import { Injectable } from "@angular/core";
import { Subject, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

    public subjectLoader = new BehaviorSubject(false);
    constructor() { }

    showLoader() {
        const html = document.querySelector('html');
        if (html.classList.contains('loading-API')) {

        } else {
            html.classList.add('loading-API');
        }
    }

    hideLoader() {
        const html = document.querySelector('html');
        if (html.classList.contains('loading-API')) {
            html.classList.remove('loading-API');
        }
    }
}